/* ProfileCard.css */

.profile-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  
  .profile-card {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 10px;
    width: 150px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    
  }
  
  .profile-name {
    margin: 0 0 10px;
    font-size: 1em;
    color:black;
    font-weight: 100;
  }
  
  .profile-title {
    margin: 0 0 10px;
    font-size: 0.5em;
    font-weight: 100;
    color: #595959;
  }
  
  .profile-description {
    font-size: 0.5em;
    color: #4f4f4f;
  }